import { createContext, JSX, useContext } from "solid-js";

import Log from "@repo/utils/Log";

type ErrorLike = string | Error;

const ErrorContext = createContext<{
  capture: (error: ErrorLike) => void;
  validate: (error: unknown) => error is ErrorLike;
}>();

export declare namespace ErrorProvider {
  type Props = {
    onError(error: ErrorLike): void;
    children: JSX.Element;
  };
}

export function ErrorProvider(props: ErrorProvider.Props) {
  return (
    <ErrorContext.Provider
      value={{
        capture(e) {
          Log.error(e);
          props.onError(e);
        },
        validate: (e) => typeof e === "string" || e instanceof Error,
      }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
}

export function useError() {
  const context = useContext(ErrorContext);
  if (!context) throw new Error("Called useError outside of ErrorProvider.");

  return context;
}
